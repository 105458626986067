<template>
    <div
        id="register"
        class="popup-bg">
        <div class="popup">
            <a
                href="javascript:void(0);"
                class="popup-close"
                @click="
                    closePopup('register');
                    reset();
                ">
            </a>
            <div class="popup-body">
                <div class="logo-wrapper">
                    <img
                        src="@/assets/img/dbc7788/logo.png"
                        alt="logo" />
                </div>
                <form @submit.prevent="submitForm">
                    <div class="form-group">
                        <label>아이디 <span>*</span></label>
                        <div class="input-group">
                            <div>
                                <input
                                    autocomplete="off"
                                    type="text"
                                    placeholder="10자 이하, (영문,숫자)"
                                    :class="{
                                        'is-invalid':
                                            submitted && v$.pl.username.$error,
                                    }"
                                    v-model="pl.username" />
                                <div
                                    v-for="(item, index) in v$.pl.username
                                        .$errors"
                                    :key="index"
                                    class="invalid-feedback">
                                    <small v-if="item.$message">
                                        {{ $t(item.$message) }}
                                    </small>
                                </div>
                            </div>
                            <button
                                type="button"
                                class="btn-yellow"
                                style="margin-left: 10px; cursor: pointer"
                                @click="checkUsername()">
                                중복확인
                            </button>
                        </div>
                        <span>10자 이하, 영문/숫자만 입력가능합니다.</span>
                    </div>
                    <div class="form-group">
                        <label>닉네임 <span>*</span></label>
                        <div class="input-group">
                            <div>
                                <input
                                    autocomplete="off"
                                    type="text"
                                    :class="{
                                        'is-invalid':
                                            submitted && v$.pl.name.$error,
                                    }"
                                    v-model="pl.name" />
                                <div
                                    v-for="(item, index) in v$.pl.name.$errors"
                                    :key="index"
                                    class="invalid-feedback">
                                    <small v-if="item.$message">
                                        {{ $t(item.$message) }}
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>비밀번호 <span>*</span></label>
                        <div class="input-group">
                            <div>
                                <input
                                    autocomplete="new-password"
                                    type="password"
                                    placeholder="4-32자로 입력해주세요."
                                    :class="{
                                        'is-invalid':
                                            submitted && v$.pl.password.$error,
                                    }"
                                    v-model="pl.password" />
                                <div
                                    v-for="(item, index) in v$.pl.password
                                        .$errors"
                                    :key="index"
                                    class="invalid-feedback">
                                    <small v-if="item.$message">
                                        {{ $t(item.$message) }}
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>비밀번호확인 <span>*</span></label>
                        <div class="input-group">
                            <div>
                                <input
                                    autocomplete="new-password"
                                    type="password"
                                    :class="{
                                        'is-invalid':
                                            submitted &&
                                            v$.pl.confirm_password.$error,
                                    }"
                                    v-model="pl.confirm_password" />
                                <div
                                    v-for="(item, index) in v$.pl
                                        .confirm_password.$errors"
                                    :key="index"
                                    class="invalid-feedback">
                                    <small v-if="item.$message">
                                        {{ $t(item.$message) }}
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>{{ $t(`mobile number`) }} <span>*</span></label>
                        <div class="input-group">
                            <div>
                                <input
                                    type="text"
                                    v-model="pl.mobile_number"
                                    :class="{
                                        'is-invalid':
                                            submitted &&
                                            v$.pl.mobile_number.$error,
                                    }" />
                                <div
                                    v-for="(item, index) in v$.pl.mobile_number
                                        .$errors"
                                    :key="index"
                                    class="invalid-feedback">
                                    <span v-if="item.$message">
                                        {{ $t(item.$message) }}
                                    </span>
                                </div>
                                <div
                                    v-for="(item, index) in v$.pl.mobile_number
                                        .$errors"
                                    :key="index"
                                    class="invalid-feedback">
                                    <small v-if="item.$message">
                                        {{ $t(item.$message) }}
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>{{ $t(`bank name`) }} <span>*</span></label>
                        <div class="input-group">
                            <div>
                                <input
                                    type="text"
                                    v-model="pl.handler_bank.physical.bank_name"
                                    :class="{
                                        'is-invalid':
                                            submitted &&
                                            v$.pl.handler_bank.physical
                                                .bank_name.$error,
                                    }" />
                                <div
                                    v-for="(item, index) in v$.pl.handler_bank
                                        .physical.bank_name.$errors"
                                    :key="index"
                                    class="invalid-feedback">
                                    <span v-if="item.$message">
                                        {{ $t(item.$message) }}
                                    </span>
                                </div>
                                <div
                                    v-for="(item, index) in v$.pl.handler_bank
                                        .physical.bank_name.$errors"
                                    :key="index"
                                    class="invalid-feedback">
                                    <small v-if="item.$message">
                                        {{ $t(item.$message) }}
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>{{ $t(`account holder`) }} <span>*</span></label>
                        <div class="input-group">
                            <div>
                                <input
                                    type="text"
                                    v-model="
                                        pl.handler_bank.physical.account_holder
                                    "
                                    :class="{
                                        'is-invalid':
                                            submitted &&
                                            v$.pl.handler_bank.physical
                                                .account_holder.$error,
                                    }" />
                                <div
                                    v-for="(item, index) in v$.pl.handler_bank
                                        .physical.account_holder.$errors"
                                    :key="index"
                                    class="invalid-feedback">
                                    <span v-if="item.$message">
                                        {{ $t(item.$message) }}
                                    </span>
                                </div>
                                <div
                                    v-for="(item, index) in v$.pl.handler_bank
                                        .physical.account_holder.$errors"
                                    :key="index"
                                    class="invalid-feedback">
                                    <small v-if="item.$message">
                                        {{ $t(item.$message) }}
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>{{ $t(`account number`) }} <span>*</span></label>
                        <div class="input-group">
                            <div>
                                <input
                                    type="text"
                                    v-model="
                                        pl.handler_bank.physical.account_number
                                    "
                                    :class="{
                                        'is-invalid':
                                            submitted &&
                                            v$.pl.handler_bank.physical
                                                .account_number.$error,
                                    }" />
                                <div
                                    v-for="(item, index) in v$.pl.handler_bank
                                        .physical.account_number.$errors"
                                    :key="index"
                                    class="invalid-feedback">
                                    <span v-if="item.$message">
                                        {{ $t(item.$message) }}
                                    </span>
                                </div>
                                <div
                                    v-for="(item, index) in v$.pl.handler_bank
                                        .physical.account_number.$errors"
                                    :key="index"
                                    class="invalid-feedback">
                                    <small v-if="item.$message">
                                        {{ $t(item.$message) }}
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label
                            >{{ $t(`charging/exchange password`) }}
                            <span>*</span></label
                        >
                        <div class="input-group">
                            <div>
                                <input
                                    type="password"
                                    maxlength="4"
                                    onkeypress=" return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 13;"
                                    v-model="pl.pin"
                                    :class="{
                                        'is-invalid':
                                            submitted && v$.pl.pin.$error,
                                    }" />
                                <div
                                    v-for="(item, index) in v$.pl.pin.$errors"
                                    :key="index"
                                    class="invalid-feedback">
                                    <span v-if="item.$message">
                                        {{ $t(item.$message) }}
                                    </span>
                                </div>
                                <div
                                    v-for="(item, index) in v$.pl.pin.$errors"
                                    :key="index"
                                    class="invalid-feedback">
                                    <small v-if="item.$message">
                                        {{ $t(item.$message) }}
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label>추천코드 <span>*</span></label>
                        <div class="input-group">
                            <div>
                                <input
                                    autocomplete="off"
                                    type="text"
                                    v-model="pl.referral_code" />
                                <!-- <div
                                    v-for="(item, index) in v$.pl.referral_code
                                        .$errors"
                                    :key="index"
                                    class="invalid-feedback">
                                    <small v-if="item.$message">
                                        {{ $t(item.$message) }}
                                    </small>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <button
                        type="submit"
                        class="btn-framed wide">
                        회원가입
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2';
import ui from '@/mixins/ui';

import {
    required,
    requiredIf,
    minLength,
    maxLength,
    maxValue,
    sameAs,
    email,
    helpers,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { mapActions } from 'vuex';

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    mixins: [ui],
    data() {
        return {
            loading: false,
            submitted: false,
            pl: {
                username: null,
                name: null,
                password: null,
                confirm_password: null,
                pin: null,
                referral_code: null,
                mobile_number: null,
                handler_bank: {
                    physical: {
                        bank_name: null,
                        account_number: null,
                        account_holder: null,
                    },
                },
            },
        };
    },
    validations() {
        return {
            pl: {
                username: {
                    required: helpers.withMessage('required', required),
                    minLength: helpers.withMessage(
                        'should be atleast 2 characters',
                        minLength(2)
                    ),
                },
                name: {
                    required: helpers.withMessage('required', required),
                    minLength: helpers.withMessage(
                        'should be atleast 2 characters',
                        minLength(2)
                    ),
                    maxLength: helpers.withMessage(
                        'maximum length is 32 characters',
                        maxLength(32)
                    ),
                },
                password: {
                    required: helpers.withMessage('required', required),
                    minLength: helpers.withMessage(
                        'should be atleast 4 characters',
                        minLength(4)
                    ),
                    maxLength: helpers.withMessage(
                        'maximum length is 32 characters',
                        maxLength(32)
                    ),
                },
                confirm_password: {
                    sameAs: helpers.withMessage(
                        `password doesn't match`,
                        sameAs(this.pl.password)
                    ),
                },
                pin: {
                    required: helpers.withMessage('required', required),
                    minLength: helpers.withMessage(
                        'must consist of 4 characters',
                        minLength(4)
                    ),
                },
                mobile_number: {
                    minLength: helpers.withMessage(
                        'should be atleast 10 characters',
                        minLength(10)
                    ),
                    maxLength: helpers.withMessage(
                        'maximum length is 15 characters',
                        maxLength(15)
                    ),
                },
                // referral_code: {
                //     required: helpers.withMessage('required', required),
                // },
                handler_bank: {
                    physical: {
                        bank_name: {
                            required: helpers.withMessage('required', required),
                        },
                        account_number: {
                            required: helpers.withMessage('required', required),
                        },
                        account_holder: {
                            required: helpers.withMessage('required', required),
                        },
                    },
                },
            },
        };
    },
    methods: {
        ...mapActions('auth', {
            authRegisterUser: 'registerUser',
            authCheckUsername: 'checkUsername',
        }),
        async submitForm() {
            if (this.loading) {
                return;
            }
            this.submitted = true;
            this.v$.pl.$touch();
            if (this.v$.pl.$invalid) {
                return;
            } else {
                var fd = new FormData();
                fd.append('username', this.pl.username);
                fd.append('name', this.pl.name);
                fd.append('password', this.pl.password);
                fd.append('confirm_password', this.pl.confirm_password);
                fd.append('mobile_number', this.pl.mobile_number);
                fd.append(
                    'handler_bank[physical][bank_name]',
                    this.pl.handler_bank.physical.bank_name
                );
                fd.append(
                    'handler_bank[physical][account_holder]',
                    this.pl.handler_bank.physical.account_holder
                );
                fd.append(
                    'handler_bank[physical][account_number]',
                    this.pl.handler_bank.physical.account_number
                );
                fd.append('pin', this.pl.pin);
                if (this.pl.referral_code) {
                    fd.append('referral_code', this.pl.referral_code);
                }
                this.loading = true;
                const res = await this.authRegisterUser(fd);
                this.loading = false;
                if (res.success) {
                    this.closePopup('register');
                    this.reset();
                    Swal.fire({
                        title: this.$t(res.data.message),
                        icon: res.data.status,
                        timer: res.success ? 2000 : null,
                        confirmButtonText: this.$t('ok'),
                    });
                } else {
                    Swal.fire({
                        title: this.$t(res.data.message),
                        icon: 'error',
                        timer: res.success ? 2000 : null,
                        confirmButtonText: this.$t('ok'),
                    });
                }
            }
        },
        async checkUsername() {
            if (this.loading) {
                return;
            }
            if (this.pl.username == '' || this.pl.username == null) {
                return;
            }
            this.v$.pl.username.$touch();
            if (this.v$.pl.username.$invalid) {
                return;
            } else {
                this.loading = true;
                const res = await this.authCheckUsername(this.pl.username);
                this.loading = false;
                Swal.fire({
                    title: this.$t(res.data.message),
                    icon: res.success ? 'success' : 'error',
                    timer: res.success ? 2000 : null,
                    confirmButtonText: this.$t('ok'),
                });
            }
        },
        async reset() {
            this.submitted = false;
            this.pl.username = null;
            this.pl.name = null;
            this.pl.password = null;
            this.pl.confirm_password = null;
            this.pl.referral_code = null;
            this.pl.mobile_number = null;
            this.pl.pin = null;
            this.pl.handler_bank.physical.bank_name = null;
            this.pl.handler_bank.physical.account_holder = null;
            this.pl.handler_bank.physical.account_number = null;
            this.v$.pl.$reset();
        },
    },
    mounted() {
        var vm = this;
        let popupBg = document.getElementById('register');
        if (popupBg) {
            popupBg.addEventListener('mousedown', function (e) {
                e = window.event || e;
                if (this === e.target) {
                    vm.reset();
                }
            });
        }
    },
};
</script>
