<template>
    <main>
        <div class="banner-wrapper">
            <!-- <div class="banner-slider">
        <div class="slide">
          <img src="@/assets/img/slide/slide1.png" alt="" />
        </div>
        <div class="slide">
          <img src="@/assets/img/slide/slide2.png" alt="" />
        </div>
      </div>
      <div class="mobile-banner-slider">
        <div class="slide">
          <img src="@/assets/img/slide/slide1.png" alt="" />
        </div>
        <div class="slide">
          <img src="@/assets/img/slide/slide2.png" alt="" />
        </div>
      </div> -->
            <div class="banner-slider">
                <carousel
                    :autoplay="5000"
                    :transition="2000"
                    :itemsToShow="1"
                    :wrapAround="true"
                    :pauseAutoplayOnHover="true">
                    <slide
                        v-for="(slide, index) in desktop_slide"
                        :key="index">
                        <img
                            :src="slide.image"
                            alt=""
                            class="slide" />
                    </slide>
                    <template #addons>
                        <navigation />
                        <pagination />
                    </template>
                </carousel>
            </div>
            <div class="mobile-banner-slider">
                <carousel
                    :autoplay="5000"
                    :transition="2000"
                    :itemsToShow="1"
                    :wrapAround="true"
                    :pauseAutoplayOnHover="true">
                    <slide
                        v-for="(slide, index) in mobile_slide"
                        :key="index">
                        <img
                            :src="slide.image"
                            alt="" />
                    </slide>
                    <template #addons>
                        <navigation />
                        <pagination />
                    </template>
                </carousel>
            </div>
        </div>
        <div class="container">
            <div
                class="auth-wrapper"
                v-if="!authenticated">
                <div class="button-group">
                    <button
                        type="button"
                        @click="openPopup('login')">
                        로그인
                    </button>
                    <button
                        type="button"
                        @click="openPopup('register')">
                        회원가입
                    </button>
                </div>
            </div>
            <div
                class="auth-wrapper"
                v-if="authenticated">
                <div class="action-wrapper">
                    <div
                        class="mobile-info"
                        style="cursor: pointer"
                        @click="goToProfile">
                        <span class="label"
                            ><img
                                style="width: 15px"
                                src="@/assets/img/user-icon.svg"
                                alt=""
                        /></span>
                        <span
                            class="value"
                            style="color: #999999"
                            >{{ profile.username }}</span
                        >
                    </div>
                    <div
                        class="mobile-info money"
                        style="cursor: pointer"
                        @click="goToTransaction">
                        <span class="label"
                            ><img
                                style="width: 15px"
                                src="@/assets/img/wallet-icon.svg"
                                alt=""
                        /></span>
                        <span class="value">{{ myBalance }}</span>
                        <span
                            class="game-indicator"
                            v-if="this.$store.state.popups.length > 0"
                            >IN-GAME</span
                        >
                        <img
                            v-if="this.$store.state.launching_game"
                            src="@/assets/img/small-loader.svg"
                            alt="" />
                    </div>
                    <div class="mobile-info money">
                        <span class="label"
                            ><img
                                style="width: 15px"
                                src="@/assets/img/point-icon.svg"
                                alt=""
                        /></span>
                        <span class="value text-info">{{
                            numberString(profile.points)
                        }}</span>
                        <a
                            href="javascript:void(0)"
                            class="dropdown"
                            @click="convertPoints"
                            >포인트머니전환</a
                        >
                    </div>
                </div>
                <button
                    style="font-size: 0.725rem"
                    type="button"
                    @click="logout"
                    class="signout-btn">
                    로그아웃
                </button>
            </div>
            <div class="content-wrapper">
                <div :class="`game-wrapper ${wrapperLayout}`">
                    <div
                        v-if="['all', 'honorlink'].includes(profile.menu.table)"
                        class="casino"
                        @click="$router.push('/vendors/casino')">
                        <span>라이브카지노</span>
                        <span>세계 최고의 카지노 게임들을 만나보세요.</span>
                    </div>
                    <div
                        v-if="['all', 'honorlink'].includes(profile.menu.slots)"
                        class="slot"
                        @click="$router.push('/vendors/slot')">
                        <span>슬롯게임</span>
                        <span>최고의 슬롯게임에서 잭팟을 도전하세요.</span>
                    </div>
                    <div
                        v-if="['all', 'kplay'].includes(profile.menu.table)"
                        class="casino"
                        @click="$router.push('/vendors/casino2')">
                        <span>라이브카지노</span>
                        <span>세계 최고의 카지노 게임들을 만나보세요.</span>
                    </div>
                    <div
                        v-if="['all', 'kplay'].includes(profile.menu.slots)"
                        class="slot"
                        @click="$router.push('/vendors/slot2')">
                        <span>슬롯게임</span>
                        <span>최고의 슬롯게임에서 잭팟을 도전하세요.</span>
                    </div>
                    <!-- <div class="minigame">
                        <span>미니게임</span>
                        <span>GLI 인증을 거친 공신력 있는 게임들.</span>
                    </div> -->
                    <div class="jackpot">
                        <div class="amount">
                            <div class="symbol">KRW</div>
                            <Vue3Odometer
                                class="odometer"
                                id="myOdometer"
                                :value="number" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="transaction-wrapper">
                <table class="transaction-table">
                    <thead>
                        <tr>
                            <th colspan="3">
                                <div class="table-heading">
                                    <span class="title">공지사항</span>
                                    <a
                                        href="javascript:void(0);"
                                        @click="goToNotice"
                                        >전체 보기</a
                                    >
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="r in data.list"
                            :key="r._id">
                            <td>{{ r.created_at }}</td>
                            <td>
                                <a
                                    href="javascript:void(0);"
                                    @click="viewNotice(r._id)"
                                    >{{ r.subject }}</a
                                >
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table class="transaction-table">
                    <thead>
                        <tr>
                            <th colspan="3">
                                <div class="table-heading">
                                    <span class="title">실시간 입금 현황</span>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(data, index) in depositNotif"
                            :key="index">
                            <td>{{ data.date }}</td>
                            <td class="amount text-start">{{ data.amount }}</td>
                            <td>{{ data.user }}</td>
                        </tr>
                    </tbody>
                </table>
                <table class="transaction-table">
                    <thead>
                        <tr>
                            <th colspan="3">
                                <div class="table-heading">
                                    <span class="title">실시간 출금 현황</span>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(data, index) in withdrawNotif"
                            :key="index">
                            <td>{{ data.date }}</td>
                            <td class="amount text-start">{{ data.amount }}</td>
                            <td>{{ data.user }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="customer-page">
                <div class="customer-content">
                    <div class="ngdialog-open customer-buttons click-disable">
                        <img
                            src="@/assets/img/faq-icon.png"
                            alt="" />
                        <div class="text-con">
                            <p>궁금한 점이 있으세요?</p>
                            <p class="goldTxt">전화문의</p>
                        </div>
                    </div>
                    <div
                        class="customer-buttons click-disable"
                        style="cursor: pointer">
                        <img
                            src="@/assets/img/inquiry-icon.png"
                            alt="" />
                        <div class="text-con">
                            <p>게임문의</p>
                            <p class="goldTxt">텔레그램</p>
                        </div>
                    </div>
                    <div
                        class="customer-buttons click-disable"
                        style="cursor: pointer">
                        <img
                            src="@/assets/img/contact-icon.png"
                            alt="" />
                        <div class="text-con">
                            <p>결제문의</p>
                            <p class="goldTxt">카톡</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="service-wrapper">
                <div>
                    <p class="title">마이페이지</p>
                    <div
                        class="btn"
                        @click="goToDeposit">
                        <img
                            src="@/assets/img/icon/deposit-button.png"
                            alt="" />
                        <p>입금신청</p>
                    </div>
                    <div
                        class="btn"
                        @click="goToWithdraw">
                        <img
                            src="@/assets/img/icon/withdraw-button.png"
                            alt="" />
                        <p>출금신청</p>
                    </div>
                    <div
                        class="btn"
                        @click="goToTicket">
                        <img
                            src="@/assets/img/icon/qa-button.png"
                            alt="" />
                        <p>1:1문의</p>
                    </div>
                    <!-- <div
                        class="btn"
                        @click="goToTicket">
                        <img
                            src="@/assets/img/icon/dm-button.png"
                            alt="" />
                        <p>쪽지함</p>
                    </div> -->
                </div>
                <div>
                    <img
                        class="logo"
                        src="@/assets/img/dbc7788/logo.png"
                        alt="" />
                    <p class="credit">Copyright 2022 All Rights Reserved.</p>
                </div>
                <div>
                    <p class="title">QUICK SERVICE</p>
                    <div class="quick-links">
                        <a href="javascript:void(0);">에볼루션</a>
                        <a href="javascript:void(0);">드림게이밍</a>
                        <a href="javascript:void(0);">WM카지노</a>
                        <a href="javascript:void(0);">TG SPEED</a>
                        <a href="javascript:void(0);">아시아게이밍</a>
                        <a href="javascript:void(0);">프라그매틱</a>
                        <a href="javascript:void(0);">베트게임즈</a>
                        <a href="javascript:void(0);">스카이윈드</a>
                        <a href="javascript:void(0);">타이산</a>
                        <a href="javascript:void(0);">두윈</a>
                        <a href="javascript:void(0);">마이크로게이밍</a>
                    </div>
                    <div class="quick-links">
                        <a href="javascript:void(0);">에볼루션</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- <ViewNoticeMainPopup :data="announcement" /> -->
        <ViewEventPopup
            :data="event"
            @closeEventPopup="closeEventPopup" />
    </main>
</template>

<script>
import dummy from '@/mixins/dummy';
import ui from '../mixins/ui';
import number from '../mixins/number';
import Vue3Odometer from 'vue3-odometer';
import { mapGetters, mapActions } from 'vuex';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import ImageDesktop1 from '@/assets/img/slide/slide1.png';
import ImageDesktop2 from '@/assets/img/slide/slide2.png';
import ImageMobile1 from '@/assets/img/slide/slider-mobile-1.png';
import ImageMobile2 from '@/assets/img/slide/slider-mobile-2.png';
import ViewNoticeMainPopup from '@/components/ViewNoticeMainPopup';
import ViewEventPopup from '@/components/ViewEventPopup';
import $cookies from 'vue-cookies';
export default {
    name: 'Main',
    components: {
        Vue3Odometer,
        Carousel,
        Slide,
        Pagination,
        Navigation,
        ViewNoticeMainPopup,
        ViewEventPopup,
    },
    data() {
        return {
            number: 419999999,
            isBtnDisabled: false,
            desktop_slide: [{ image: ImageDesktop1 }, { image: ImageDesktop2 }],
            mobile_slide: [{ image: ImageMobile1 }, { image: ImageMobile2 }],
            withdrawNotif: [],
            depositNotif: [],

            data: {
                list: [],
                links: [],
                current_page: 1,
                per_page: 1,
                last_page: 1,
            },
            announcement: {
                content: '',
                subject: '',
                user: {},
                created_at: '',
            },

            events: {
                list: [],
                links: [],
                current_page: 1,
                per_page: 1,
                last_page: 1,
            },
            event: {
                _id: null,
                content: '',
                subject: '',
                user: {},
                created_at: '',
            },
        };
    },
    mixins: [number, ui, dummy],
    watch: {
        'profile.role'(role) {
            if (role) {
                this.initEvents(1);
            }
        },
    },
    computed: {
        ...mapGetters('auth', ['authenticated', 'profile']),
        wrapperLayout() {
            var divCount = 0;
            if (this.profile.menu.table == 'all') {
                divCount += 2;
            } else {
                divCount += 1;
            }

            if (this.profile.menu.slots == 'all') {
                divCount += 2;
            } else {
                divCount += 1;
            }

            var flexStyle = '';
            switch (divCount) {
                case 2:
                case 4:
                    flexStyle = 'd-50';
                    break;
                case 3:
                    flexStyle = 'd-33';
                    break;
            }

            return flexStyle;
        },

        hideGamesTwo() {
            return this.authenticated;
        },
    },
    methods: {
        ...mapActions('auth', {
            authLogout: 'logoutUser',
            authGetProfile: 'getProfile',
        }),
        ...mapActions('announcement', {
            getAnnouncementList: 'getList',
            viewAnnouncement: 'view',
        }),
        ...mapActions('event', {
            getEventList: 'getList',
            viewEvent: 'view',
        }),
        pollData() {
            this.polling = setInterval(() => {
                this.number += this.randomInt(10, 1000);
            }, 2000);
        },
        async logout() {
            this.isBtnDisabled = true;
            const res = await this.authLogout();
            if (res) {
                this.$router.replace({ path: '/' });
            }
            // this.hideGamesTwo = false;
            this.isBtnDisabled = false;
        },
        goToTicket() {
            if (!this.authenticated) {
                this.openPopup('login');
            } else {
                this.openPopup('ticket-popup');
            }
        },
        goToDeposit() {
            if (!this.authenticated) {
                this.openPopup('login');
            } else {
                this.openPopup('deposit-popup');
            }
        },
        goToWithdraw() {
            if (!this.authenticated) {
                this.openPopup('login');
            } else {
                this.openPopup('withdraw-popup');
            }
        },

        goToProfile() {
            if (!this.authenticated) {
                this.openPopup('login');
            } else {
                this.openPopup('my-page');
            }
        },
        goToTransaction() {
            if (!this.authenticated) {
                this.openPopup('login');
            } else {
                this.openPopup('transaction-popup');
            }
        },
        goToNotice() {
            if (!this.authenticated) {
                this.openPopup('login');
            } else {
                this.openPopup('notice-popup');
            }
        },
        generateUser(length) {
            var characters =
                'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
            var num = '0123456789';
            let resultChar = '';
            let resultNum = '';
            var charactersLength = characters.length;
            for (let i = 0; i < length; i++) {
                resultChar += characters.charAt(
                    Math.floor(Math.random() * charactersLength)
                );
            }
            var numLength = num.length;
            for (let x = 0; x < length; x++) {
                resultNum += num.charAt(Math.floor(Math.random() * numLength));
            }

            return resultChar + '*****' + resultNum;
        },
        async transDisplay() {
            var intCount = 5;
            for (let i = 1; i <= intCount; i++) {
                var amountRandom = [
                    '100,000',
                    '200,000',
                    '300,000',
                    '400,000',
                    '500,000',
                    '800,000',
                    '100,000',
                    '2,000,000',
                    '3,000,000',
                    '4,000,000',
                    '5,000,000',
                    '8,000,000',
                    '10,000',
                    '20,000',
                    '30,000',
                    '40,000',
                    '50,000',
                    '80,000',
                ];
                var random = Math.floor(Math.random() * amountRandom.length);
                var date = new Date().toISOString().slice(0, 10);
                var time = new Date().toLocaleTimeString('en-US', {
                    hour12: false,
                    hour: 'numeric',
                    minute: 'numeric',
                });
                var characters =
                    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
                var num = '0123456789';
                let resultChar = '';
                let resultNum = '';
                var charactersLength = characters.length;
                for (let i = 0; i < 2; i++) {
                    resultChar += characters.charAt(
                        Math.floor(Math.random() * charactersLength)
                    );
                }
                var numLength = num.length;
                for (let x = 0; x < 2; x++) {
                    resultNum += num.charAt(
                        Math.floor(Math.random() * numLength)
                    );
                }
                // console.log(amountRandom[random]);
                var pl = {
                    date: date.replace(/\//g, '-') + ' ' + time,
                    amount: amountRandom[random] + ' 원',
                    user: resultChar + '*****' + resultNum,
                };
                await this.withdrawNotif.unshift(pl);
            }
            for (let i = 1; i <= intCount; i++) {
                var amountRandom = [
                    '100,000',
                    '200,000',
                    '300,000',
                    '400,000',
                    '500,000',
                    '800,000',
                    '100,000',
                    '2,000,000',
                    '3,000,000',
                    '4,000,000',
                    '5,000,000',
                    '8,000,000',
                    '10,000',
                    '20,000',
                    '30,000',
                    '40,000',
                    '50,000',
                    '80,000',
                ];
                var random = Math.floor(Math.random() * amountRandom.length);
                var date = new Date().toISOString().slice(0, 10);
                var time = new Date().toLocaleTimeString('en-US', {
                    hour12: false,
                    hour: 'numeric',
                    minute: 'numeric',
                });
                var characters =
                    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
                var num = '0123456789';
                let resultChar = '';
                let resultNum = '';
                var charactersLength = characters.length;
                for (let i = 0; i < 2; i++) {
                    resultChar += characters.charAt(
                        Math.floor(Math.random() * charactersLength)
                    );
                }
                var numLength = num.length;
                for (let x = 0; x < 2; x++) {
                    resultNum += num.charAt(
                        Math.floor(Math.random() * numLength)
                    );
                }
                // console.log(amountRandom[random]);
                var pl = {
                    date: date.replace(/\//g, '-') + ' ' + time,
                    amount: amountRandom[random] + ' 원',
                    user: resultChar + '*****' + resultNum,
                };
                await this.depositNotif.unshift(pl);
            }
        },
        async transactionWithdrawNotif() {
            var amountRandom = [
                '100,000',
                '200,000',
                '300,000',
                '400,000',
                '500,000',
                '800,000',
                '100,000',
                '2,000,000',
                '3,000,000',
                '4,000,000',
                '5,000,000',
                '8,000,000',
                '10,000',
                '20,000',
                '30,000',
                '40,000',
                '50,000',
                '80,000',
            ];
            var random = Math.floor(Math.random() * amountRandom.length);
            var date = new Date().toISOString().slice(0, 10);
            var time = new Date().toLocaleTimeString('en-US', {
                hour12: false,
                hour: 'numeric',
                minute: 'numeric',
            });
            var characters =
                'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
            var num = '0123456789';
            let resultChar = '';
            let resultNum = '';
            var charactersLength = characters.length;
            for (let i = 0; i < 2; i++) {
                resultChar += characters.charAt(
                    Math.floor(Math.random() * charactersLength)
                );
            }
            var numLength = num.length;
            for (let x = 0; x < 2; x++) {
                resultNum += num.charAt(Math.floor(Math.random() * numLength));
            }
            if (this.withdrawNotif.length >= 5) {
                this.withdrawNotif.pop();
            }
            // console.log(ti);
            var pl = {
                date: date.replace(/\//g, '-') + ' ' + time,
                amount: amountRandom[random] + ' 원',
                user: resultChar + '*****' + resultNum,
            };
            await this.withdrawNotif.unshift(pl);
            setTimeout(this.transactionWithdrawNotif, 6000);
        },
        async transactionDepositNotif() {
            var amountRandom = [
                '100,000',
                '200,000',
                '300,000',
                '400,000',
                '500,000',
                '800,000',
                '100,000',
                '2,000,000',
                '3,000,000',
                '4,000,000',
                '5,000,000',
                '8,000,000',
                '10,000',
                '20,000',
                '30,000',
                '40,000',
                '50,000',
                '80,000',
            ];
            var random = Math.floor(Math.random() * amountRandom.length);
            // console.log(amountRandom[random]);
            var date = new Date().toISOString().slice(0, 10);
            var time = new Date().toLocaleTimeString('en-US', {
                hour12: false,
                hour: 'numeric',
                minute: 'numeric',
            });
            var characters =
                'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
            var num = '0123456789';
            let resultChar = '';
            let resultNum = '';
            var charactersLength = characters.length;
            for (let i = 0; i < 2; i++) {
                resultChar += characters.charAt(
                    Math.floor(Math.random() * charactersLength)
                );
            }
            var numLength = num.length;
            for (let x = 0; x < 2; x++) {
                resultNum += num.charAt(Math.floor(Math.random() * numLength));
            }
            if (this.depositNotif.length >= 5) {
                this.depositNotif.pop();
            }
            // console.log(ti);
            var pl = {
                date: date.replace(/\//g, '-') + ' ' + time,
                amount: amountRandom[random] + ' 원',
                user: resultChar + '*****' + resultNum,
            };
            await this.depositNotif.unshift(pl);
            setTimeout(this.transactionDepositNotif, 4000);
        },
        async initAnnoucementList(p) {
            var pl = {
                page_no: p,
                limit: 5,
            };
            this.loading = true;
            if (!this.authenticated) {
                // this.openPopup('login');
                this.data.list = [
                    {
                        _id: '65b8584859266ec0890cdf47',
                        user_id: '65b0ff908b329923f306f835',
                        subject: 'Lorem ipsum',
                        updated_at: '2024-01-30 10:00:40',
                        created_at: '2024-01-30 10:00:40',
                        user: {
                            _id: '65b0ff908b329923f306f835',
                            username: 'admin-04',
                            name: 'Admin',
                        },
                    },
                    {
                        _id: '65b8582a17bc8928d30bdba3',
                        user_id: '65b0ff908b329923f306f835',
                        subject: 'Big Annoucement for all',
                        updated_at: '2024-01-30 10:00:10',
                        created_at: '2024-01-30 10:00:10',
                        user: {
                            _id: '65b0ff908b329923f306f835',
                            username: 'admin-04',
                            name: 'Admin',
                        },
                    },
                    {
                        _id: '65b857df2c2fd163fd0819de',
                        user_id: '65b0ff908b329923f306f835',
                        subject:
                            '\u203b \ubca0\ud305 \uaddc\uc815 \uc548\ub0b4 3\u203b',
                        updated_at: '2024-01-30 09:58:55',
                        created_at: '2024-01-30 09:58:55',
                        user: {
                            _id: '65b0ff908b329923f306f835',
                            username: 'admin-04',
                            name: 'Admin',
                        },
                    },
                    {
                        _id: '65b857ca59266ec0890cdf45',
                        user_id: '65b0ff908b329923f306f835',
                        subject:
                            '\u203b \ubca0\ud305 \uaddc\uc815 \uc548\ub0b4 2\u203b',
                        updated_at: '2024-01-30 09:58:34',
                        created_at: '2024-01-30 09:58:34',
                        user: {
                            _id: '65b0ff908b329923f306f835',
                            username: 'admin-04',
                            name: 'Admin',
                        },
                    },
                    {
                        _id: '65b78b0729fe47f66d0d3236',
                        user_id: '65b0ff908b329923f306f835',
                        subject:
                            '\u203b \ubca0\ud305 \uaddc\uc815 \uc548\ub0b4  1\u203b',
                        updated_at: '2024-01-29 19:24:55',
                        created_at: '2024-01-29 19:24:55',
                        user: {
                            _id: '65b0ff908b329923f306f835',
                            username: 'admin-04',
                            name: 'Admin',
                        },
                    },
                ];
                // this.data.links = res.links;
                // this.data.current_page = res.current_page;
                // this.data.per_page = res.per_page;
                // this.data.last_page = res.last_page;
                this.loading = false;
            } else {
                const res = await this.getAnnouncementList(pl);
                this.data.list = res.data;
                this.data.links = res.links;
                this.data.current_page = res.current_page;
                this.data.per_page = res.per_page;
                this.data.last_page = res.last_page;
                this.loading = false;
            }
        },

        async viewNotice(id) {
            if (this.authenticated) {
                this.openPopup('view-notice-main-popup');
                const data = await this.viewAnnouncement(id);

                // console.log(data);
                this._id = data._id;
                this.announcement.subject = data.subject;
                this.announcement.content = data.content;
                this.announcement.user.name = data.user.name;
                this.announcement.user.username = data.user.username;
                this.announcement.created_at = data.created_at;
            } else {
                this.openPopup('login');
            }
        },
        async initEvents(p) {
            var pl = {
                page_no: p ? p : 1,
                limit: 5,
            };
            const res = await this.getEventList(pl);
            this.events.list = res.data;
            this.events.links = res.links;
            this.events.current_page = res.current_page;
            this.events.per_page = res.per_page;
            this.events.last_page = res.last_page;

            if (res.data.length > 0 && $cookies.get('hideEventPopup') == null) {
                const e = await this.viewEvent(res.data[0]._id);
                console.log(e);
                this.event._id = e.data._id;
                this.event.subject = e.data.subject;
                this.event.content = e.data.content;
                this.event.user.name = e.data.user.name;
                this.event.user.username = e.data.user.username;
                this.event.created_at = e.data.created_at;
            }
            this.loading = false;
        },
        closeEventPopup() {
            this.event._id = null;
            this.event.subject = null;
            this.event.content = null;
            this.event.user = {};
            this.event.created_at = null;
        },
    },
    mounted() {
        this.pollData();
        this.transDisplay();
        this.transactionWithdrawNotif();
        this.transactionDepositNotif();
        if (this.profile.role) {
            this.initAnnoucementList(1);
            this.initEvents(1);
        }

        // window.addEventListener('beforeunload', (event) => {
        //     // console.log(event);
        //     // return 'Are you really want to perform the action?';
        //     event.preventDefault();
        //     event.returnValue = `Are you sure you want to leaveeee?`;
        // });
        // this.hideGamesTwo = this.authenticated;
        // console.log(this.authenticated);
    },
};
</script>

<style></style>
