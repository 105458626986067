<template>
    <div
        id="casino2"
        class="popup-bg games">
        <div class="popup">
            <div class="popup-header">
                <div class="title">라이브 카지노</div>
                <a
                    href="javascript:void(0);"
                    class="popup-close"
                    @click="closePopup('casino2')">
                </a>
            </div>
            <div class="ngdialog-content">
                <div class="ngdialog-games-page">
                    <div class="game-button-container live live_list">
                        <div
                            class="game-buttons play_live"
                            v-for="(r, index) in data.list"
                            :key="index">
                            <img
                                v-if="r.game_thumbnail"
                                class="game"
                                :src="
                                    require('@/assets/img/thumbnail/casino/' +
                                        r.game_thumbnail)
                                "
                                alt="" />

                            <img
                                v-else
                                class="logo"
                                src="@/assets/img/dbc7788/logo.png"
                                alt="logo"
                                style="width: 100%" />
                            <span class="title">{{ r.name }}</span>
                            <button
                                type="button"
                                :disabled="loading"
                                @click="showGameDetailsCasino(r)"
                                class="btn-yellow btn-play">
                                게임입장
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ui from '@/mixins/ui';
import Swal from 'sweetalert2';
export default {
    data() {
        return {
            loading: false,
            data: {
                list: [],
                links: [],
                current_page: 0,
                last_page: 0,
                per_page: 0,
                total: 0,
            },
        };
    },
    mixins: [ui],

    computed: {
        ...mapGetters('auth', ['authenticated']),
    },
    methods: {
        ...mapActions('casinoGame', {
            getCasinoGame: 'getList',
            casinoGameLaunch2: 'getGameLaunch2',
        }),
        async initList() {
            const res = await this.getCasinoGame('casino2_lobby_en');
            // const res2 = await this.getCasinoGame('casino2_lobby_kr');
            // var casino_products = [];
            // res.data.forEach((r) => {
            //     const prd = res2.data.find((r2) => r2.prd_id == r.product);
            //     if (prd) {
            //         r.name = prd.prd_name;
            //         casino_products.push(r);
            //     }
            // });
            // console.log(JSON.stringify(casino_products));

            // const res2 = await this.getCasinoGame('casino2_lobby_before');
            // var casino_products = [];
            // res.data.forEach((r) => {
            //     const prd = res2.data.find((r2) => r2.product == r.product);
            //     if (prd) {
            //         r.game_thumbnail = prd.game_thumbnail;
            //     }
            //     casino_products.push(r);
            // });
            // console.log(JSON.stringify(casino_products));

            // var casino_products = [];
            // res.data.forEach((r) => {
            //     if (
            //         [
            //             1, 2, 3, 5, 6, 9, 10, 12, 16, 17, 18, 19, 21, 22, 24,
            //             26, 27, 30,
            //         ].includes(r.product)
            //     ) {
            //         r.product = r.product.toString()
            //         casino_products.push(r);
            //     }
            // });
            // console.log(JSON.stringify(casino_products));
            this.data.list = res.data;
        },

        async showGameDetailsCasino(r) {
            if (!this.authenticated) return;
            const pl = {
                product: r.product,
                language: r.language,
                game: r.game,
            };
            if (this.oneGameAtATime) {
                Swal.fire({
                    title: this.$t(
                        'To proceed, please close the game that is currently in progress.'
                    ),
                    type: 'error',
                    icon: 'error',
                    confirmButtonText: this.$t('ok'),
                });
                return;
            }
            this.loading = true;
            const res = await this.casinoGameLaunch2(pl);
            this.loading = false;
            if (res.status == 200) {
                var queryParams = {
                    url: res.data.data.url,
                };

                var queryString = Object.keys(queryParams)
                    .map(
                        (key) =>
                            key + '=' + encodeURIComponent(queryParams[key])
                    )
                    .join('&');
                var window_name =
                    this.gameLaunchMode === 'single'
                        ? 'game'
                        : `game-${r.product}`;

                const isMobile = {
                    Android: function () {
                        return navigator.userAgent.match(/Android/i);
                    },
                    BlackBerry: function () {
                        return navigator.userAgent.match(/BlackBerry/i);
                    },
                    iOS: function () {
                        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
                    },
                    Opera: function () {
                        return navigator.userAgent.match(/Opera Mini/i);
                    },
                    Windows: function () {
                        return (
                            navigator.userAgent.match(/IEMobile/i) ||
                            navigator.userAgent.match(/WPDesktop/i)
                        );
                    },
                    any: function () {
                        return (
                            isMobile.Android() ||
                            isMobile.BlackBerry() ||
                            isMobile.iOS() ||
                            isMobile.Opera() ||
                            isMobile.Windows()
                        );
                    },
                };
                try {
                    if (isMobile.any()) {
                        this.current_window = window.location.replace(
                            `/game-launch/casino?${queryString}`,
                            window_name,
                            'width=' +
                                screen.availWidth +
                                ',height=' +
                                screen.availHeight +
                                ',fullscreen=yes, toolbar=no, location=no, directories=no, status=no, menubar=no,scrollbars=no,resizable=no'
                        );
                    } else {
                        this.current_window = window.open(
                            `/game-launch/casino?${queryString}`,
                            window_name,
                            'width=' +
                                screen.availWidth +
                                ',height=' +
                                screen.availHeight +
                                ',fullscreen=yes, toolbar=no, location=no, directories=no, status=no, menubar=no,scrollbars=no,resizable=no'
                        );
                    }
                } catch (e) {}
            } else {
                Swal.fire({
                    title: this.$t(res.data.message),
                    type: 'error',
                    icon: 'error',
                    confirmButtonText: this.$t('ok'),
                });
            }
        },
    },
    mounted() {
        this.initList();
    },
};
</script>

<style></style>
