<template>
    <div
        id="slot2"
        class="popup-bg games">
        <div class="popup">
            <div class="popup-header">
                <div class="title">슬롯게임</div>
                <a
                    href="javascript:void(0);"
                    class="popup-close"
                    @click="closePopup('slot2')">
                </a>
            </div>
            <div class="ngdialog-content">
                <div class="ngdialog-games-page">
                    <div class="game-button-container live live_list">
                        <div
                            class="game-buttons play_live"
                            v-for="(r, index) in data.list"
                            :key="index">
                            <!-- <h1>{{ index + 1 }}. {{ r.prd_id }}</h1> -->
                            <img
                                v-if="r.game_thumbnail"
                                class="game"
                                :src="
                                    require('@/assets/img/thumbnail/slot/' +
                                        r.game_thumbnail)
                                "
                                alt="" />
                            <img
                                v-else
                                class="game"
                                src="@/assets/img/dbc7788/logo.png"
                                alt="logo"
                                style="width: 100%" />

                            <span class="title">{{ r.prd_name }}</span>
                            <button
                                type="button"
                                :disabled="loading"
                                @click="showGameDetailsSlot(r)"
                                class="btn-yellow btn-play">
                                게임입장
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import ui from '@/mixins/ui';
import Swal from 'sweetalert2';
export default {
    data() {
        return {
            loading: false,
            data: {
                list: [],
                links: [],
                current_page: 0,
                last_page: 0,
                per_page: 0,
                total: 0,
            },
        };
    },
    mixins: [ui],
    computed: {
        ...mapGetters('auth', ['authenticated']),
        ...mapState('slotGame', {
            slot_game: 'data',
        }),
    },
    methods: {
        ...mapActions('slotGame', {
            getSlotGame: 'getList',
            slotGameLaunch: 'getGameLaunch',
        }),

        async initList() {
            const res = await this.getSlotGame('slot2_lobby_kr');
            // const res2 = await this.getSlotGame('slot2_lobby_kr');
            // var slot_products = [];
            // res.data.forEach((r) => {
            //     const prd = res2.data.find((r2) => r2.prd_id == r.prd_id);
            //     if (prd) {
            //         r.prd_name = prd.prd_name;
            //         slot_products.push(r);
            //     }
            // });
            // console.log(JSON.stringify(slot_products));

            this.data.list = res.data;
        },
        async showGameDetailsSlot(r) {
            if (!this.authenticated) return;
            if (this.oneGameAtATime) {
                Swal.fire({
                    title: this.$t(
                        'To proceed, please close the game that is currently in progress.'
                    ),
                    type: 'error',
                    icon: 'error',
                    confirmButtonText: this.$t('ok'),
                });
                return;
            }
            this.loading = true;
            this.$router.push({
                path: `/kplay/slot_detail`,
                query: {
                    vendor: r.prd_name,
                    product: r.prd_id,
                },
            });
            this.loading = false;
        },
    },
    mounted() {
        this.initList();
    },
};
</script>
<style></style>
